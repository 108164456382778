import { memo, useState, useEffect } from 'react'
import List from '../List/List'
import { DATA1, DATA2, DATA3, DATA4 } from './const'
import { Button, Input, Upload, message, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { http, uplaodFetch } from '@/server/http'
import { apiGroup } from '@/server/api-group'
import CommonFunc from '@/utils/common-func'
const { TextArea } = Input
import './VideoCreation.less'
const VideoCreation = (props: any) => {
  const [active, setActive] = useState(props.active)
  const [loading, setLoading] = useState(false)
  const [imgLoading, setImgLoading] = useState(true)
  const [list, setList] = useState([])
  const [text, setText] = useState('')
  const [imageUrl, setImageUrl] = useState<string>()
  const unitoken = localStorage.getItem('uniToken')
  const getType = (type: any) => (type == 3 ? 1 : 2)
  useEffect(() => {
    console.log(setLoading, imgLoading)
    setActive(props.active)
    task_list(props.active)
    setImageUrl('')
    setText('')
  }, [props.active])
  const task_list = async (type: any) => {
    const res: any = await http('post', apiGroup.task_list, { unitoken, type: getType(type) })
    setList(res?.data?.list)
  }
  const textChange = (e: any) => {
    console.log(e.target.value, loading)
    setText(e.target.value)
  }
  const beforeUpload = async (file: any) => {
    const isImage = file.type.startsWith('image/')
    if (!isImage) {
      message.error('You can only upload image files!')
      return
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (isLt2M) {
      uploadImg(file)
    } else {
      message.error('Image must smaller than 2MB!')
      // // 图片压缩
      // const preFileSize = file.size / 1024
      // compressImage(file, preFileSize)
    }
  }
  // const compressImage = async (file: any, preSize: any) => {
  //   const compressedFile: any = await CommonFunc.compressImage(file)
  //   // console.log(compressedFile.size / 1024, preSize)
  //   if (compressedFile.size / 1024 < 500) {
  //     uploadImg(compressedFile)
  //   } else {
  //     const f = await new File([compressedFile], compressedFile.name, { type: compressedFile.type })
  //     if (Math.floor(f.size / 1024) === Math.floor(preSize)) {
  //       uploadImg(f)
  //     } else {
  //       compressImage(f, f.size / 1024)
  //     }
  //     // message.error('Image must smaller than 2MB!')
  //   }
  // }
  const uploadImg = async (file: any) => {
    const form = new FormData()
    form.append('imgs', file)
    const res: any = await uplaodFetch(process.env.VITE_UPLOAD, form)
    const url = process.env.VITE_IMG + res.data
    setImgLoading(true)
    setImageUrl(url)
  }
  // const handleChange = (info: any) => {
  //   if (info.file.status === 'uploading') {
  //     setLoading(true)
  //     return
  //   }
  //   if (info.file.status === 'done') {
  //     // Get this url from response in real world.
  //     getBase64(info.file.originFileObj as any, url => {
  //       setLoading(false)
  //       setImageUrl(url)
  //     })
  //   }
  // }
  const submit = async () => {
    if (!text) {
      message.error('请填写作品链接')
      return
    }
    if (!imageUrl) {
      message.error('请上传作品截图')
      return
    }
    try {
      const res: any = await http('post', apiGroup.task_submit, { unitoken, text, image: imageUrl, type: getType(active) })
      if (res.status) {
        setImageUrl('')
        setText('')
        message.success('任务提交成功')
        task_list(active)
      } else {
        message.error('提交失败，请检查作品链接或联系客服')
      }
    } catch {
      message.error('提交失败，请检查作品链接或联系客服')
    }
  }
  return (
    <div className='VideoCreation-container'>
      <div className='desc-text'>
        <div className='left'>
          <List data={DATA1[active]} title='任务步骤：' />
          <List data={DATA2[active]} title='作品要求：' />
        </div>
        <div className='right'>
          <List className='done-list' data={DATA3[active]} title='完成标准：' />
          <List data={DATA4[active]} title='任务说明：' />
        </div>
      </div>
      <div className='user-do'>
        <div className='left'>
          <div className='user-ipt'>
            <TextArea
              onChange={textChange}
              value={text}
              placeholder='粘贴作品链接（只保留纯链接，如 https://v.douyin.com/ifM3NAv7/）'
              style={{ resize: 'none' }}
            />
            <Upload
              name='avatar'
              fileList={[]}
              className='avatar-uploader'
              accept={'.png,.jpg,.jpeg,.gif,.webp'}
              multiple={false}
              listType='picture-card'
              beforeUpload={beforeUpload}
              customRequest={(info: any) => {
                console.log(info)
                return
              }}
            >
              {imageUrl ? (
                <>
                  {imgLoading && <Spin indicator={<LoadingOutlined spin />} size='small' />}
                  <img
                    src={imageUrl}
                    alt='avatar'
                    style={{ maxWidth: '100%', maxHeight: '100%', display: imgLoading ? 'none' : 'block' }}
                    onLoad={() => setImgLoading(false)} // 图片加载成功
                    onError={() => {
                      setImgLoading(false) // 图片加载失败，停止显示加载状态
                    }}
                  />
                </>
              ) : (
                <i className='icon iconfont icon-shangchuantupian' />
              )}
            </Upload>
          </div>
          <Button type='primary' onClick={submit}>
            提交任务
          </Button>
        </div>
        <div className='right'>
          {list?.length == 0 ? (
            <div className='no-data'>暂无提交记录</div>
          ) : (
            <ul>
              {list.map((item: any, index: number) => {
                return (
                  <li key={index}>
                    <span className='date'>{CommonFunc.timestampToTime(item.created_at, false, 'YYYY.MM.DD hh:mm')}</span>
                    <span className='text'>{item.text}</span>
                    <span className='status'>{item.status}</span>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(VideoCreation)
