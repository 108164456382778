import { memo } from 'react'
import './UpToBottomScroll.less'

const data = [
  { title: '张同学 本月佣金收益', comein: '5392.35' },
  { title: '高同学 本月佣金收益', comein: '4631.98' },
  { title: '罗同学 本月佣金收益', comein: '4998.33' },
  { title: '王同学 本月佣金收益', comein: '3568.50' },
  { title: '蒋同学 本月佣金收益', comein: '3955.30' },
  { title: '孔同学 本月佣金收益', comein: '3568.35' },
  { title: '姜同学 本月佣金收益', comein: '3098.11' },
  { title: '鲁同学 本月佣金收益', comein: '2998.20' },
  { title: '赵同学 本月佣金收益', comein: '2890.00' },
  { title: '孙同学 本月佣金收益', comein: '2830.50' }
]

const UpToBottomScroll = (props: any) => {
  return (
    <div className={`upToBottomScroll-container ${props.className}`}>
      {/* <div className='mb'></div> */}
      <ul>
        {data.concat(data).map((item: any, index: number) => (
          <li key={index}>
            {item.title} {item.comein}元
          </li>
        ))}
      </ul>
    </div>
  )
}

export default memo(UpToBottomScroll)
