import { runse, kuoxie, suoxie, piyue, chtoen, aigc } from '@/utils/global-const'
export const DATA = [
  { level: 1, title: '第一章 绪论', content: [] },
  {
    level: 2,
    title: '1.1 引言',
    content: [
      '圆盘钢翻转装置是冶金自动化生产线上的重要组成部分，其被设计的好坏程度直接影响了整个自动化控制生产线的工作水平。设计圆盘钢翻转装置的内部机械结构，利用齿轮齿条传动并结合液压传动系统即可实现圆盘钢的翻转动作；设计圆盘钢翻转装置的液压传动系统并分析其工作原理。结果表明：利用液压传动系统进行控制机械装置的运动，可以很好地实现圆盘钢的翻转。该液压圆盘钢翻转装置具有结构简单、输出力大、惯性较小以及易于自动控制等优点，与电气控制、可编程控制器和计算机控制等相结合，可组成各式自动化机械，在自动化生产中具有广阔的应用前景。'
    ],
    en: [
      'Workpiece turnover device is an important part of automatic production line． It has directly influence on the level of automatic production line．Mechanical structure of the workpiece turnover device was designed． The gear—rack transmission and hydraulic transmission system were used to turn workpiece． Hydraulic transmission system of the device was designed and its working principle was analyzed． The results show that workpiece turnover is achieved well by using hydraulic system to control mechanical device．The hydraulic turnover device has the characters of simple structure， high power output，low inertia and easy contro1． A kind of automation machinery can be formed by unite it with electric control，PLC and computer contro1． It has wide application prospect in automatic production．'
    ]
  },
  {
    level: 2,
    title: '1.2 液压传动的特点与简介',
    content: [
      '液压传动是以液压液作为工作介质对能量进行传递和控制的一种传动形式，相对于机械传动来说，它是一门新技术。但如从17世纪末巴斯卡提出静压传递原理，18世纪末英国制成世界上第一台水压机算起，也有二三百年的历史了。而近代液压传动在工业上的真正推广使用，则是在本世纪中叶以后的事。近几十年来，随着微电子技术的迅速发展，且渗透到液压技术中并与之密切结合，是其应用领域遍及到各个工业部门，已成为实现生产过程自动化、提高劳动生产率等必不可少的重要手段之一。',
      '液压传动：它是以液压油为工作介质，通过动力元件（油泵）将原动机的机械能变为液压油的压力能，再通过控制元件，然后借助执行元件(油缸或油马达)将压力能转换为机械能，驱动负载实现直线或回转运动，且通过对控制元件遥控操纵和对流量的调节，调定执行元件的力和速度。当外界对上述系统有扰动时，执行元件的输出量一般要偏离原有调定值，产生一定的误差。',
      '液压控制：和液压传动一样，系统中也包括动力元件、控制元件和执行元件，也是通过油液传递功率。二者不同之点是液压控制具有反馈装置，反馈装置的作用是执行元件的输出量（位移、速度、力等机械量）反馈回去与输入量（可以是变化的，也可以是恒定的）进行比较，用比较后的偏差来控制系统，使执行元件的输出随输入量的变化而变化或保持恒定。它是一种构成闭环回路的液压传动系统，也叫液压随动系统或液压伺服系统。液压传动系统中用的是通断式或逻辑式控制元件，就其控制目的，是保持被调定值的稳定或单纯变换方向，也叫定值和顺序控制元件。'
    ]
  },
  {
    level: 3,
    title: '1.2.1 液压传动的优缺点',
    desc: '介绍液压系统在工业装置中的应用背景，特别是在圆盘钢翻转装置中的重要性',
    content: [
      '在目前四大类传动方式（机械、电气、液压和气压）中，没有一种动力传动是十全十美的，而液压传动具有下述极其明显的优点：',
      '（1）从结构上看，其单位重量的输出功率和单位尺寸输出功率在四类传动方式中是力压群芳的，有很大的力矩惯量比，在传递相同功率的情况下，液压传动装置的体积小、重量轻、惯性小、结构紧凑、布局灵活。',
      '（2）从工作性能上看，速度、扭矩、功率均可无级调节，动作响应性快，能迅速换向和变速，调速范围宽，调速范围可达100：1到2000：1；动作快速性好，控制、调节比较简单，操纵比较方便、省力，便于与电气控制相配合，以及与CPU（计算机）的连接，便于实现自动化。',
      '（3）从使用维护上看，元件的自润滑性好，易实现过载保护与保压，安全可靠；元件易于实现系列化、标准化、通用化。',
      '（4）所有采用液压技术的设备安全可靠性好。',
      '（5）经济：液压技术的可塑性和可变性很强，可以增加柔性生产的柔度，和容易对生产程序进行改变和调整，液压元件相对说来制造成本也不高，适应性比较强。',
      '（6）液压易与微机控制等新技术相结合，构成“机—电—液—光”一体化已成为世界发展的潮流，便于实现数字化。'
    ]
  },
  {
    level: 3,
    title: '1.2.2 液压传动在翻转机中的应用和发展',
    desc: '阐述设计高效液压系统对于提升装置工作效率，安全性及延长使用寿命的重要价值',
    content: []
  },
  { level: 2, title: '1.3 圆盘钢翻转装置液压系统的工作过程', content: [] }
]

export const LUNWEN_CLASSIFY = [
  {
    title: '课程论文',
    enTitle: 'Course Work',
    desc1: '全文4000~6000字',
    desc2: '二级大纲结构',
    desc3: '侧重课程知识的理解和应用',
    desc4: '标准课程论文结构'
  },
  {
    title: '毕业论文 (本科)',
    enTitle: 'Undergraduate Thesis',
    desc1: '全文10000~20000字',
    desc2: '三级大纲结构',
    desc3: '更高的学术深度和研究难度',
    desc4: '严格的方法论和理论分析',
    desc5: '标准本科论文结构'
  },
  {
    title: '毕业论文 (专科)',
    enTitle: 'Diploma Thesis',
    desc1: '全文6000~10000字',
    desc2: '二级大纲结构',
    desc3: '侧重应用技能和基础理论',
    desc4: '标准专科论文结构'
  }
]
export const duplicateRemoval = [
  {
    title: '智能降重',
    enTitle: 'Reduce Similarity',
    desc1: '支持国内主流查重报告导入',
    desc2: [
      { label: '中国知网', icon: 'icon-zhiwang' },
      { label: '万方数据库', icon: 'icon-a-470x470-01' },
      { label: '维普检测', icon: 'icon-logo' }
    ],
    desc3: '全程安全性高度保障 云端数据0存储',
    desc4: '全球领先的4.0大模型 查重率低于15%',
    desc5: '逐段优化 速度更快 效率更高',
    btn: '开始降重'
  },
  {
    title: '智能降AIGC',
    enTitle: 'Reduce AI-Generated Content',
    desc1: '支持识别全球各大主流AI大模型',
    desc2: [
      { label: 'ChatGPT', icon: 'icon-gpt1' },
      { label: 'Claude', icon: 'icon-Claude-2' },
      { label: 'Gemini', icon: 'icon-google' },
      { label: 'Llama', icon: 'icon-Meta' },
      { label: '文心一言', icon: 'icon-baiduchatgpt' }
      // { label: 'More', icon: '' }
    ],
    desc3: '全球领先的检测内核  极高的准确性、可靠性',
    desc4: '先进的NPL人类语言大模型 AIGC率低于15%',
    desc5: '逐段优化 速度更快 效率更高',
    btn: '开始降AIGC'
  }
]
export const data = [
  { name: runse, notllowedAnimation: true },
  { name: kuoxie, notllowedAnimation: true },
  { name: suoxie, notllowedAnimation: true },
  { name: piyue, notllowedAnimation: true }
]
export const data2 = data.concat([{ name: chtoen, notllowedAnimation: true }])
export const data3 = [
  { name: runse, notllowedAnimation: false },
  { name: kuoxie, notllowedAnimation: false },
  { name: suoxie, notllowedAnimation: false },
  { name: piyue, notllowedAnimation: false },
  { name: aigc, notllowedAnimation: false }
]
export const LIST = [
  {
    data: data3,
    actived: 0,
    left: [
      '圆盘钢翻转装置是冶金自动化生产线上的重要组成部分，其被设计的好坏程度直接影响了整个自动化控制生产线的工作水平。设计圆盘钢翻转装置的内部机械结构，利用齿轮齿条传动并结合液压传动系统即可实现圆盘钢的翻转动作；设计圆盘钢翻转装置的液压传动系统并分析其工作原理。结果表明：利用液压传动系统进行控制机械装置的运动，可以很好地实现圆盘钢的翻转。该液压圆盘钢翻转装置具有结构简单、输出力大、惯性较小以及易于自动控制等优点，与电气控制、可编程控制器和计算机控制等相结合，可组成各式自动化机械，在自动化生产中具有广阔的应用前景。'
    ],
    right: [
      '圆盘钢翻转装置在冶金自动化生产线中扮演着关键角色，其设计质量直接关乎整个自动化控制生产线的运行效率。该装置的内部机械结构采用齿轮和齿条的传动机制，辅以液压系统，以达成圆盘钢的精确翻转。同时，对液压传动系统进行了设计和工作原理的深入分析。研究表明，液压传动系统的引入能够有效控制机械装置的动作，确保圆盘钢翻转的顺畅进行。这种液压翻转装置以其结构简洁、输出力强劲、动态响应快速及易于集成自动控制等特点而突显优势。结合电气控制、可编程逻辑控制器（PLC）以及计算机控制技术，该装置能够构成多样化的自动化机械系统，为自动化生产领域提供了广阔的应用潜力。'
    ],
    title: 'AI UniPaper 润色/降重已有段落',
    desc: '保持观点不变，对语言、逻辑、结构和表达方式进行完善，可大幅提高论文的质量和可读性',
    btn: '开始润色'
  },
  {
    data: data3,
    actived: 1,
    left: [
      '圆盘钢翻转装置是冶金自动化生产线上的重要组成部分，其被设计的好坏程度直接影响了整个自动化控制生产线的工作水平。设计圆盘钢翻转装置的内部机械结构，利用齿轮齿条传动并结合液压传动系统即可实现圆盘钢的翻转动作；设计圆盘钢翻转装置的液压传动系统并分析其工作原理。结果表明：利用液压传动系统进行控制机械装置的运动，可以很好地实现圆盘钢的翻转。该液压圆盘钢翻转装置具有结构简单、输出力大、惯性较小以及易于自动控制等优点，与电气控制、可编程控制器和计算机控制等相结合，可组成各式自动化机械，在自动化生产中具有广阔的应用前景。'
    ],
    right: [
      '圆盘钢翻转装置的作用在于确保冶金自动化生产线中的圆盘钢材料能够在加工过程中准确无误地翻转，以适应不同的加工要求。这种装置的设计精良程度将直接决定生产线的运行效率和产品质量。为此，专家们精心设计了这一装置的内部机械结构，通过巧妙地运用齿轮和齿条的配合，以及液压传动系统的支持，实现了对圆盘钢进行精准控制的翻转动作。',
      '在设计圆盘钢翻转装置的过程中，工程师们还对液压传动系统进行了详尽的设计与原理分析，确保系统的稳定性和可靠性。液压传动系统的引入，使得机械装置的运动控制更加灵活和精确，从而使圆盘钢的翻转工作得以顺利完成。通过实际的运行测试，结果证实了液压系统在控制机械装置运动方面的高效性能。',
      '此外，液压圆盘钢翻转装置具备了多项引人注目的优势。它的结构设计简单明了，易于维护与操作；输出力量大，能够处理重型圆盘钢材料；动态响应迅速，惯性小，能够快速适应生产线的变化需求；并且，这种装置非常适合与自动控制技术相结合，如电气控制系统、可编程逻辑控制器（PLC），以及更为先进的计算机控制系统。这种高度的兼容性和灵活性使得液压圆盘钢翻转装置可以轻松地融入各种自动化机械配置中，大大提升了生产线的自动化水平和智能化程度。'
    ],
    title: 'AI UniPaper 扩写已有段落',
    desc: '将结构简单、内容贫乏、不明确、不具体、不生动的段落，扩充得丰富、充实、鲜明、形象',
    btn: '开始扩写'
  },
  {
    data: data3,
    actived: 2,
    left: [
      '圆盘钢翻转装置的作用在于确保冶金自动化生产线中的圆盘钢材料能够在加工过程中准确无误地翻转，以适应不同的加工要求。这种装置的设计精良程度将直接决定生产线的运行效率和产品质量。为此，专家们精心设计了这一装置的内部机械结构，通过巧妙地运用齿轮和齿条的配合，以及液压传动系统的支持，实现了对圆盘钢进行精准控制的翻转动作。',
      '在设计圆盘钢翻转装置的过程中，工程师们还对液压传动系统进行了详尽的设计与原理分析，确保系统的稳定性和可靠性。液压传动系统的引入，使得机械装置的运动控制更加灵活和精确，从而使圆盘钢的翻转工作得以顺利完成。通过实际的运行测试，结果证实了液压系统在控制机械装置运动方面的高效性能。',
      '此外，液压圆盘钢翻转装置具备了多项引人注目的优势。它的结构设计简单明了，易于维护与操作；输出力量大，能够处理重型圆盘钢材料；动态响应迅速，惯性小，能够快速适应生产线的变化需求；并且，这种装置非常适合与自动控制技术相结合，如电气控制系统、可编程逻辑控制器（PLC），以及更为先进的计算机控制系统。这种高度的兼容性和灵活性使得液压圆盘钢翻转装置可以轻松地融入各种自动化机械配置中，大大提升了生产线的自动化水平和智能化程度。'
    ],
    right: [
      '圆盘钢翻转装置是冶金自动化生产线上的重要组成部分，其被设计的好坏程度直接影响了整个自动化控制生产线的工作水平。设计圆盘钢翻转装置的内部机械结构，利用齿轮齿条传动并结合液压传动系统即可实现圆盘钢的翻转动作；设计圆盘钢翻转装置的液压传动系统并分析其工作原理。结果表明：利用液压传动系统进行控制机械装置的运动，可以很好地实现圆盘钢的翻转。该液压圆盘钢翻转装置具有结构简单、输出力大、惯性较小以及易于自动控制等优点，与电气控制、可编程控制器和计算机控制等相结合，可组成各式自动化机械，在自动化生产中具有广阔的应用前景。'
    ],
    title: 'AI UniPaper 缩写已有段落',
    desc: '确保核心思想不变，并保证逻辑、连贯性的同时，对其进行简化和概括，可节约篇幅、突出重点',
    btn: '开始缩写'
  },
  {
    data: data3,
    actived: 3,
    left: [
      '圆盘钢翻转装置是冶金自动化生产线上的重要组成部分，其被设计的好坏程度直接影响了整个自动化控制生产线的工作水平。设计圆盘钢翻转装置的内部机械结构，利用齿轮齿条传动并结合液压传动系统即可实现圆盘钢的翻转动作；设计圆盘钢翻转装置的液压传动系统并分析其工作原理。结果表明：利用液压传动系统进行控制机械装置的运动，可以很好地实现圆盘钢的翻转。该液压圆盘钢翻转装置具有结构简单、输出力大、惯性较小以及易于自动控制等优点，与电气控制、可编程控制器和计算机控制等相结合，可组成各式自动化机械，在自动化生产中具有广阔的应用前景。'
    ],
    right: [
      '这段论文的内容基本上是正确的，但是在表达和格式上还有一些可以改进的地方。以下是我的一些建议：',
      '1.文章开头可以更加正式一些，比如：“在冶金自动化生产线中，圆盘钢翻转装置扮演着至关重要的角色。”',
      '2.当描述设计的重要性时，建议使用更加精确的语言，比如：“圆盘钢翻转装置的设计质量对整个自动化生产线的性能具有决定性影响。”',
      '3.在描述技术细节时，可以更加具体和清晰，例如：“本设计采用了齿轮和齿条传动机制，辅以液压系统，以实现圆盘钢的翻转动作。”',
      '4.分析工作原理时，应该更加详细地说明液压系统是如何工作的，而不是简单地说结果表明它有效。',
      '5.结论部分的句式可以更加简洁，同时避免重复，例如：“该液压翻转装置结构简单、力量输出强大、惯性小，易于集成到自动控制系统中，如与电气控制、可编程逻辑控制器（PLC）和计算机控制系统相结合，将极大地拓展其在自动化生产领域的应用潜力。”',
      '修正后的段落如下：',
      '在冶金自动化生产线中，圆盘钢翻转装置扮演着至关重要的角色。该装置的设计质量对整个生产线的性能具有决定性影响。本设计采用了齿轮和齿条传动机制，辅以液压系统，以实现圆盘钢的翻转动作，并对液压传动系统的工作原理进行了详细分析。研究结果显示，通过液压系统控制的机械装置能够有效实现圆盘钢的翻转。该液压翻转装置结构简单、力量输出强大、惯性小，易于集成到自动控制系统中。结合电气控制、可编程逻辑控制器（PLC）和计算机控制系统，该装置在自动化生产领域具有广泛的应用潜力。'
    ],
    title: 'AI UniPaper 批阅已有段落',
    desc: '模拟论文导师，对段落的内容、表达、格式等进行批阅纠错，并提出修改建议、给出正确示例',
    btn: '开始批阅'
  },
  {
    data: data3,
    actived: 4,
    left: [
      '在探索节能减排行为的经济学解释时，行为经济学的视角提供了一种新的理解途径。传统经济学理论以理性行为为基础，但在许多情况下，这一假设并不能完全解释人们的实际行为。节能减排行为的研究，特别是在面对全球性的环境挑战时，显得尤为重要。传统的理性选择理论在解释节能减排行为时存在局限，它忽略了人类决策过程中的非理性因素，如情绪和认知偏差。行为经济学的出现弥补了这一缺陷，它通过考虑个体在决策中的心理偏差和情绪影响，提供了一个更加精细和符合现实的分析框架[2]。这一框架有助于我们理解为什么个体和集体在面对长期的环境风险时，仍可能偏向于追求短期利益。此外，行为经济学揭示了非理性决策背后的心理机制，这不仅对于设计更高效的环境政策至关重要，而且还能帮助我们预测和引导公众的环保行为。'
    ],
    right: [
      '在探讨节能减排行为的经济学解释时，行为经济学这种视角提供了一条崭新的理解之道。以理性行为为基础的传统经济学理论，在许多情况下，有时候并不能完全解释人们实际上各种相对复杂繁琐的具体行为。节能减排行为的研究，在面对全球性的环境挑战时，显得尤为重要。在解释节能减排行为时存在局限的是传统的理性选择理论，它忽略了人类决策过程中的情绪和认知偏差。行为经济学弥补了这一缺陷，并且通过考虑在决策中的心理偏差和情绪影响的个体，提供了一个更加精细和符合现实的分析框架。这一架构有益于我们的理解，为什么在面对持续时间复杂的环境危险时，个别和整体倾向于寻求立竿见影的利益。此外，行为经济学揭示了非常不理性决策背后的心理机制，这不仅对于设计更高效的环境政策至关重要，而且还能帮助我们预测和引导公众的环保行为。'
    ],
    title: 'AI UniPaper 已有段落降AIGC率',
    desc: '模拟论文导师，对段落的内容、表达、格式等进行批阅纠错，并提出修改建议、给出正确示例',
    btn: '开始降AIGC'
  }
]
export const SAFETY = [
  {
    title: '一、本平台高度重视论文数据安全，用户所生成的全部论文内容决不会在云端存储，只会保存在用户设备的本地缓存中',
    desc: '论文数据全部存储在浏览器的Cookie数据中，用户可随时通过清除浏览器缓存的方式删除全部论文数据'
  },
  {
    title: '二、本平台高度重视用户隐私安全，平台中任何功能决不会索取、记录、存储与用户身份及隐私有关的信息和数据',
    desc: '平台采取身份令牌（UniToken）的方式对用户身份进行识别，仅用于用户区分，并不会与用户的任何个人信息进行关联'
  }
]
export const AIS = [
  { name: '[百度] 文心大模型', url: 'https://yiyan.baidu.com' },
  { name: '[科大讯飞] 星火通用大模型', url: 'https://xinghuo.xfyun.cn' },
  { name: '[商汤] 商汤商量通用大模型', url: 'https://platform.sensenova.cn' },
  { name: '[清华技术成果转化] 智谱AI ChatGLM', url: 'http://open.bigmodel.cn/pricing' },
  { name: '[阿里云] 通义千问通用大模型', url: 'https://tongyi.aliyun.com' },
  { name: '[字节跳动] 云雀大模型（豆包）', url: 'https://www.doubao.com' },
  { name: '[腾讯] 混元大模型', url: 'https://cloud.tencent.com/product/hunyuan' }
]
export const BaseProgramData1 = [
  { name: 'Human', percent: '4.67%' },
  { name: 'Mixed 疑似', percent: '5.03%' },
  { name: 'AI', percent: '90.30%' } // 93.30
]
export const BaseProgramData2 = [
  { name: 'Human', percent: '91.84%' },
  { name: 'Mixed 疑似', percent: '4.08%' },
  { name: 'AI', percent: '5.98%' }
]
export const ADVANTAGE = [
  { chName: '论文及隐私安全', enName: 'Papers and privacy security' },
  { chName: '全文采用 4.0 模型', enName: 'Advanced 4.0 Super Model' },
  { chName: '支持表格、公式、代码', enName: 'Support Table、Formula、Code' },
  { chName: '无限次免费大纲', enName: 'Unlimited Free Outline' },
  { chName: '超低查重率 <15%', enName: 'The Repetition Rate < 15%' },
  { chName: '引用知网真实文献', enName: 'CNKI Real References' }
]

export const REPORT_LEFT = [
  {
    h4: '第一章 研究背景及意义',
    content: [
      { title: '1.1 工业自动化对液压系统的需求', desc: '工业自动化的发展推动了液压系统的广泛应用' },
      { title: '1.2 圆盘钢翻转机构在机械制造中的重要性', desc: '圆盘钢翻转机构在机械制造中具有重要作用' }
    ]
  },
  {
    h4: '第二章 国内外研究现状和发展',
    content: [
      { title: '2.1 国内液压系统设计的现状', desc: '国内液压系统设计的现状及其特点' },
      { title: '2.2 国外液压系统设计的先进技术', desc: '国外液压系统设计的先进技术及其应用' }
    ]
  }
]

export const REPORT_RIGHT = [
  {
    h4: '参考文献',
    content: [
      '[1] 王益群,高殿荣.液压工程师技术手册[M].北京：化学工业出版社，2011，1-979',
      '[2] 成大先.液压传动[M].北京：化学工业出版社，2004，1-817',
      '[3] 成大先.机械设计手册-电机与电器[M].北京：化学工业出版社，2011，1-831',
      '[4] 成大先.机械设计手册第五卷[M].北京：化学工业出版社，2001，21-298'
    ]
  },
  {
    h4: '研究进度计划',
    content: [
      '2024年10月01日 - 2024年10月12日  文献回顾和研究预备阶段',
      '2024年10月13日 - 2024年10月26日  数据收集和处理阶段',
      '2024年10月27日 - 2024年11月10日  分析和解释阶段',
      '2024年11月11日 - 2024年11月21日  撰写论文阶段',
      '2024年11月21日 - 2024年12月05日  论文审阅和修改阶段',
      '2024年12月05日 - 2024年12月15日 论文提交和答辩阶段'
    ]
  }
]

export const AccordionDatas = [
  {
    icon: <i className='icon iconfont icon-chatgpt' />,
    title: '全文采用GPT-4模型',
    content: '全球领先AI模型 严格保证论文质量'
  },
  {
    icon: <i className='icon iconfont icon-zhiwang1' />,
    title: '知网真实文献',
    content: '全部文献源自知网 严格保证论文严谨'
  },
  {
    icon: <i className='icon iconfont icon-sheweimiandenglu' />,
    title: '免登录模式',
    content: '采用UniToken令牌 严格保障隐私安全'
  },
  {
    icon: <i className='icon iconfont icon-zhianjiandushouxu' />,
    title: '论文数据安全',
    content: '论文数据云端0存储 严格保障论文安全'
  },
  {
    icon: <i className='icon iconfont icon-icon-jihuarenwushuchazhong' />,
    title: '查重率低于15%',
    content: '全部内容均为AI原创 严格保证查重率'
  }
]
