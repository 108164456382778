import func2 from '@/assets/images/func2.png'
import func1 from '@/assets/images/func1.png'
export const items = [
  {
    key: '1',
    label: '如何注册？如何登录？',
    children: (
      <div className='box'>
        <span>AI UniPaper 无需注册！无需登录！</span>
        <span>为保障个人信息安全及论文数据安全，平台采用 UniToken（身份令牌）的免登录模式。</span>
        <span>
          用户在进入任意功能时，只需按弹窗提示领取 UniToken（身份令牌），UniToken
          为系统随机生成，仅用于用户区分，并不会包含任何的个人身份信息。
        </span>
      </div>
    )
  },
  {
    key: '2',
    label: '什么是UniToken？',
    children: (
      <div className='box'>
        <span>
          UniToken（身份令牌）是用户唯一的身份证明，可查询到用户的充值数据、消耗记录，用户必须单独记录保存，如若丢失或遗忘，本平台将不承担任何责任，且不存在找回功能。
        </span>
      </div>
    )
  },
  {
    key: '3',
    label: '平台中免费功能有哪些？',
    children: <img className='img' src={func2} />
  },
  {
    key: '4',
    label: '平台中付费功能有哪些？',
    children: <img className='img' src={func1} />
  },
  {
    key: '5',
    label: '如何退出登录？',
    children: (
      <div className='box'>
        <span>
          UniToken、全部论文数据均存储在用户本机的浏览器Cookie中，用户需要通过“清除浏览器缓存”的方式清除全部用户信息，实现退出登录。
        </span>
        <span>这种方式会清除全部的论文数据，请务必谨慎操作！（建议在清除之前使用“存档”功能对全部数据进行备份）。</span>
      </div>
    )
  },
  {
    key: '6',
    label: '开题报告、论文记录、PPT生成记录在哪里查看？',
    children: (
      <div className='box'>
        <span>全部的开题报告正文、课程论文正文、毕业论文正文、PPT记录、降重记录、降AIGC记录，均可在“论文管理”列表中查看。</span>
      </div>
    )
  },
  {
    key: '7',
    label: '平台如何保证论文安全？',
    children: (
      <div className='box'>
        <span>
          本平台高度重视论文数据安全，用户所生成的全部论文内容绝不会在云端存储，仅保存在用户设备的本地缓存（浏览器Cookie数据）中，用户可随时通过清除浏览器缓存的方式删除全部论文数据。
        </span>
      </div>
    )
  },
  {
    key: '8',
    label: '平台使用的AI模型有何优势？',
    children: (
      <div className='box'>
        <span>
          平台中所有AI内容生成功能（包含开题报告、课程论文、毕业论文、段落修改）均采用全球领先的GPT-4
          强化模型，其理解度、准确性、连贯性、推理能力、辩证能力、数据分析能力均保持国际领先水平。
        </span>
      </div>
    )
  },
  {
    key: '9',
    label: '生成的论文查重率有多少？',
    children: (
      <div className='box'>
        <span>
          平台所生成的开题报告、课程论文、毕业论文内容均为AI原创，并非在各网络平台检索产生，所以查重率会极低，平台保证在15%以下（一般在5%左右）。
        </span>
      </div>
    )
  },
  {
    key: '10',
    label: '生成的论文AIGC率有多少？',
    children: (
      <div className='box'>
        <span>
          正文中直接生成的内容AIGC率不能保证，约在30%以内。如果学校对AIGC率有要求，请使用本平台的“智能降AIGC”功能，平台可保证降AIGC后的段落AIGC率在15%以内（一般在5%左右）。
        </span>
      </div>
    )
  },
  {
    key: '11',
    label: '引用的参考文献是真实的吗？',
    children: (
      <div className='box'>
        <span>
          开题报告、课程论文、毕业论文中所引用的参考文献均来自于中国知网，均为真实文献，用户可点击文献列表中的跳转按钮进入知网查看文献原文。
        </span>
      </div>
    )
  },
  {
    key: '12',
    label: '论文中可以生成表格、公式、代码吗？',
    children: (
      <div className='box'>
        <span>论文目录中支持对每个段落插入表格、公式、计算机代码，用户可根据自身需要自行对段落进行标记。</span>
      </div>
    )
  },
  {
    key: '13',
    label: '生成一篇完整的论文全文需要多久？',
    children: (
      <div className='box'>
        <span>目前平台所有的文本类功能均采用国际领先的4.0模型版本，生成效率大幅提高，一篇1万字的正文5分钟即可生成完成。</span>
      </div>
    )
  },
  {
    key: '14',
    label: '如何在其他电脑上继续写作？',
    children: (
      <div className='box'>
        <span>
          ① 导出存档：在“用户信息”弹窗中，使用“导出存档”功能可对全部论文数据进行备份，平台会下载后缀为 “.paper“ 的存档文件。
        </span>
        <span>
          （注：存档数据包含该UniToken下所生成的全部论文正文、开题报告、PPT、降重记录、降AIGC记录的源数据，采用加密形式存储。因存档文件包含全部论文数据，请用户必须妥善保管）。
        </span>
        <span>
          ② 导入存档：在新电脑上访问 AI
          UniPaper官网，点击任意功能后，在“领取身份令牌”弹窗中选择“导入存档”功能，即可显示全部论文数据及账户相关数据，完成继续创作。
        </span>
      </div>
    )
  },
  {
    key: '15',
    label: '费用说明',
    children: (
      <div className='box'>
        <span>
          平台以AI生成结果的字数计费，消耗字数前平台会有弹窗提示与用户进行二次确认，字数消耗记录可在“用户信息”弹窗中查看（免费功能不消耗字数）。
        </span>
        <span>字数规则：1 个汉字为 1 个字；1 个英文单词为 1 个字。</span>
        <span>PPT券用于PPT生成时使用，1 份PPT将消耗 1 张PPT券。</span>
      </div>
    )
  },
  {
    key: '16',
    label: '数据备份建议',
    children: (
      <div className='box'>
        <span>建议每次编辑论文后使用“导出存档”功能对最新论文数据进行备份，并按存档的导出时间自行管理最新存档。</span>
      </div>
    )
  },
  {
    key: '17',
    label: '隐私说明',
    children: (
      <div className='box'>
        <span>
          1、平台承诺：本平台高度重视论文数据安全及用户隐私安全，平台绝不会记录任何用户个人信息；使用过程中所产生的全部论文数据仅保存在浏览器Cookie中，用户可随时通过清除浏览器缓存的方式删除所有数据。
        </span>
        <span>
          2、重要数据：UniToken
          令牌是用户唯一的身份证明，包含用户的充值数据，用户必须单独记录保存，如若丢失或遗忘，本平台将不承担任何责任，且不存在找回功能。
        </span>
        <span>
          3、存档数据：存档数据包含论文目录、正文、参考文献等全部论文数据，采用加密形式存储，可在其他新设备上导入使用。因存档文件包含全部论文数据，请用户必须妥善保管。
        </span>
      </div>
    )
  }
]
