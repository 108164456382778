export const LEFT = {
  1: [
    '① 分享专属邀请链接给新同学',
    '② 新同学打开链接，按页面提示 “接受邀请” -> “领取身份令牌”',
    '③ 新同学需领取免费体验字数或购买字数'
  ],
  2: [
    '① 分享专属邀请链接给新同学',
    '② 新同学打开链接，按页面提示 “接受邀请” -> “领取身份令牌”',
    '③ 新同学需领取免费体验字数或购买字数'
  ]
} as any

export const RIGHT = {
  1: [
    '① 每成功邀请5人，点击“领取”按钮即可领取10,000字',
    '② 被邀请的新同学基础购买项可享8折优惠',
    '③ 本任务邀请人数上限为10人',
    '④ 每一个邀请名额只能帮助你完成一个任务'
  ],
  2: [
    '① 每成功邀请5人，点击“领取”按钮即可领取PPT券1张',
    '② 被邀请的新同学基础购买项可享8折优惠',
    '③ 本任务邀请人数上限为10人',
    '④ 每一个邀请名额只能帮助你完成一个任务'
  ]
} as any

export const BTN_TEXT = {
  1: '领取10,000字',
  2: '领取PPT券1张'
} as any

export const GET_WORDS = '已完成'
export const NO_WORDS = '未领取体验字数或购买字数'
export const CODE = `强烈推荐 AI UniPaper『专注大学论文』
免费生成开题报告、标准毕业论文正文、自动生成精美PPT、降重降AIGC、模拟导师批阅、段落修改，采用全球领先GPT-4内核
☞ 请使用「电脑端」进入
https://www.aiunipaper.com/?i=`
