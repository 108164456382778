import { memo, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Button } from 'antd'
import './InvitationModal.less'
// const unityUrl = process.env.VITE_NAME
const InvitationModal = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(props.isModalOpen)
  const [code, setCode] = useState(props.code)
  useEffect(() => {
    setIsModalOpen(props.isModalOpen)
  }, [props.isModalOpen])
  useEffect(() => {
    setCode(props.code)
  }, [props.code])
  const handleOk = () => {
    if (props.onOk) {
      props.onOk()
    }
  }
  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }
  return (
    <>
      <Modal
        wrapClassName={`${props.className || ''} invitationModal-modal-container`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title=''
        footer={null}
        centered
      >
        <p>来自好友邀请</p>
        <div className='title1'>邀请码：{code}</div>
        <div className='title2'>邀请专享福利：全部充值享8折优惠</div>

        <div className={`btn-container`}>
          <Button type='primary' onClick={handleCancel}>
            取消
          </Button>
          <Button type='primary' onClick={handleOk}>
            接受邀请
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default memo(withRouter(InvitationModal))
