import { memo, useState, useEffect } from 'react'
import CommonFunc from '@/utils/common-func'
import './EasyTable.less'
const EasyTable = (props: any) => {
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  useEffect(() => {
    setData(props.dataSource || [])
    setColumns(props.columns || [])
  }, [props.dataSource, props.columns])
  return (
    <div className={`easyTable-container ${props.className}`}>
      {props.title ? <h4 className='title'>{props.title}</h4> : null}
      <ul className='easy-table'>
        <li className='easy-table-thead'>
          <div className='tr'>
            {columns.map((item: any, index: number) => {
              return (
                <div className='th' key={index} style={{ ...item.style }}>
                  {item.title}
                </div>
              )
            })}
          </div>
        </li>
        <li className='easy-table-tbody' style={{ ...props.tbodyStyle }}>
          {data.map((item: any, index: number) => {
            return (
              <div className='tr' key={index}>
                {columns.map((column: any, idx: number) => {
                  return (
                    <div className='td' key={idx} style={{ ...column.style }}>
                      {column.dataIndex === 'created_at'
                        ? CommonFunc.timestampToTime(item[column.dataIndex])
                        : item[column.dataIndex] || 1}
                      {item[column.dataIndex] ? column.unit : '张'}
                    </div>
                  )
                })}
              </div>
            )
          })}
          {data.length === 0 ? (
            <div className='no-data'>
              <i className='icon iconfont icon-wujilu' />
              <span>暂无记录</span>
            </div>
          ) : null}
        </li>
      </ul>
    </div>
  )
}

export default memo(EasyTable)
