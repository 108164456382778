import { memo, useState, useEffect } from 'react'
import './List.less'
const List = (props: any) => {
  const [data, setData] = useState(props.data || [])
  const [title, setTitle] = useState(props.title || '')
  useEffect(() => {
    setData(props.data)
  }, [props.data])
  useEffect(() => {
    setTitle(props.title)
  }, [props.title])
  return (
    <div className={`List-container ${props.className}`}>
      <h4>{title}</h4>
      <ul>
        {data.map((item: any, index: number) => {
          return <li key={index}>{item}</li>
        })}
      </ul>
      {props.children}
    </div>
  )
}

export default memo(List)
