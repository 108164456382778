/* eslint-disable @typescript-eslint/default-param-last */
/**
 * 网络请求配置
 */
import axios from 'axios'
// import qs from 'qs'
// const CancelToken = axios.CancelToken
axios.defaults.timeout = 1000 * 60
axios.defaults.baseURL = '/'
// declare const window: any
export const chatUrl = 'https://opai.easylink.vip/api/completions2?payload='
export const check_aigc = 'https://api.gptzero.me/v2/predict/text'
/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  (config: any) => {
    // config.data = qs.stringify(config.data)
    config.headers = {
      'Content-Type': 'application/json',
      'x-api-key': 'b8f43ff57a304227a57dc92f73c3c95b',
      Authorization: 'Bearer 6AqZ6jjBt7sDSukURB5m5BT1HMvlUGbgUEftshMFeecbdd7e'
      // 'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  response => {
    if (response.data.errCode === 2) {
      console.log('过期')
    }
    return response
  },
  error => {
    console.log('请求出错：', error)
    return error.response
  }
)

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url: string, params = {}, signal?: any) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: params, signal })
      .then(response => {
        // landing(url, params, response.data)
        resolve(response.data)
      })
      .catch(error => {
        msag(error)
        reject(error)
      })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url: string, data: any, signal?: any) {
  return new Promise((resolve, reject) => {
    axios.post(url, { ...data }, { signal }).then(
      response => {
        // console.log('response', response)
        resolve(response?.data || '手动取消请求')
      },
      err => {
        msag(err)
        reject(err)
      }
    )
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

//统一接口处理，返回数据
// eslint-disable-next-line @typescript-eslint/default-param-last
export const http = (type: string, url: string, param: any = {}, signal?: any) => {
  return new Promise((resolve, reject) => {
    switch (type) {
      case 'get':
        console.log('begin a get request,and url:', url)
        get(url, param, signal)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log('get request GET failed.', error)
            reject(error)
          })
        break
      case 'post':
        post(url, param, signal)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log('get request POST failed.', error)
            reject(error)
          })
        break
      default:
        break
    }
  })
}

//失败提示
function msag(err: any) {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        console.log(err.response.data.error.details)
        break
      case 401:
        console.log('未授权，请登录')
        break

      case 403:
        console.log('拒绝访问')
        break

      case 404:
        console.log('请求地址出错')
        break

      case 408:
        console.log('请求超时')
        break

      case 500:
        console.log('服务器内部错误')
        break

      case 501:
        console.log('服务未实现')
        break

      case 502:
        console.log('网关错误')
        break

      case 503:
        console.log('服务不可用')
        break

      case 504:
        console.log('网关超时')
        break

      case 505:
        console.log('HTTP版本不受支持')
        break
      default:
    }
  }
}

/**
 * 查看返回的数据
 * @param url
 * @param params
 * @param data
 */
// function landing(url: string, params: any, data: any) {
//   if (data.code === -1) {
//     console.log(1111)
//   }
// }
export const uplaodFetch = (url: any, form: any) => {
  return new Promise((resolve: any) => {
    const xhr = new XMLHttpRequest()
    xhr.open('post', url, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        const responseObj = JSON.parse(xhr.response)
        if (responseObj.status) {
          resolve(responseObj)
        }
      }
    }
    // 执行请求
    xhr.send(form)
  })
}
// export const uplaodFetch = (url: any, form: any) => {
//   return new Promise((resolve: any, reject: any) => {
//     const xhr = new XMLHttpRequest()
//     xhr.open('post', url, true)
//     xhr.onreadystatechange = () => {
//       if (xhr.readyState === 4) {
//         try {
//           const responseObj = JSON.parse(xhr.response)
//           if (responseObj.status) {
//             resolve(responseObj)
//           } else {
//             reject(new Error('Upload failed'))
//           }
//         } catch (error) {
//           reject(new Error('Failed to parse response'))
//         }
//       }
//     }
//     // 执行请求
//     xhr.send(form)
//   })
// }

export const fetchFile = async (url: string, toBob = true) => {
  try {
    const response = await fetch(url.split('vip')[1])
    const blob = await response.blob()
    // console.log(blob)
    if (toBob) {
      return blob
    } else {
      const fileName = url.substring(url.lastIndexOf('/') + 1)
      const file = new File([blob], fileName, { type: 'application/msword' })
      // 你可以在这里处理文件，例如将其上传到服务器或下载
      return file
    }
  } catch (error) {
    console.error('Error fetching the file:', error)
  }
}
