import { memo, useState, useEffect } from 'react'
import { Modal, Button } from 'antd'
import MoreModal from '@/components/MoreModal/MoreModal'
import dailiPng from '@/assets/images/dailiPng.jpg'
// import SvgIcon from '@/components/SvgIcon/SvgIcon'
import './DailiModal.less'
const DailiModal = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(props.visible)
  const [lookMore, setLookMore] = useState(false)
  useEffect(() => {
    setIsModalOpen(props.visible)
  }, [props.visible])
  const handleOk = () => {
    if (props.onOk) {
      props.onOk()
    }
  }
  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }
  return (
    <>
      <Modal
        wrapClassName={`${props.className || ''} dailiModal-modal-container`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title=''
        footer={null}
        centered
      >
        <img src={dailiPng} />
        <Button type='primary' className='ant-btn-size3' onClick={() => setLookMore(true)}>
          点击查看更多权益
        </Button>
        {props.children && props.children}
      </Modal>
      {lookMore && <MoreModal visible={lookMore} onCancel={() => setLookMore(false)} />}
    </>
  )
}

export default memo(DailiModal)
