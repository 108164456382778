import { memo, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Collapse } from 'antd'
import { items } from './const'
import './PlatformModal.less'
const PlatformModal = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(props.visible)

  useEffect(() => {
    setIsModalOpen(props.visible)
  }, [props.visible])
  const handleOk = () => {
    if (props.onOk) {
      props.onOk()
    }
  }
  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }
  const onChange = (key: string | string[]) => {
    console.log(key)
  }
  return (
    <Modal
      wrapClassName={`${props.className || ''} platformModal-modal-container easy-modal`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      title=''
      footer={null}
      centered
    >
      <h3>
        <i className='icon iconfont icon-wenhao' />
        AI UniPaper 平台使用说明
      </h3>
      <Collapse items={items} ghost={true} onChange={onChange} accordion={true} />
    </Modal>
  )
}

export default memo(withRouter(PlatformModal))
