export const data = [
  {
    title: '购买字数',
    activity: 0,
    icon: 'icon-zishu',
    content: ['毕业论文（本科/专科）', '开题报告', '课程论文', '降重/降AIGC', '段落修改'],
    desc: [
      {
        title: '解锁全部文本类功能',
        subTitle: '生成正文（逐段生成/一键全文）、AI在线改稿'
      },
      {
        title: '永久有效',
        subTitle: '充值字数无过期时间、随时可用'
      },
      {
        title: '支持多人使用',
        subTitle: '一人充值、全班可用，无用户限制'
      }
    ],
    unit: '万字',
    originPrice: 30,
    generalUser: { title: '9折优惠 限时福利', sendTitle: '充3万字 送PPT券', price: 27, id: 101, sendId: 1 },
    inviteUser: { title: '校园代理邀请 限时8折优惠', sendTitle: '充3万字 送PPT券', price: 24, id: 102, sendId: 1 }
  },
  {
    title: '购买PPT券',
    activity: 1,
    icon: 'icon-PPTkuangjia',
    content: ['支持Office、WPS二次编辑', '精美主题', '精美布局', '生成PPT', '下载PPT'],
    desc: [
      {
        title: '解锁全部PPT功能',
        subTitle: '支持生成并下载.pptx源文件、支持二次编辑'
      },
      {
        title: '永久有效',
        subTitle: 'PPT券无过期时间、随时可用'
      },
      {
        title: '支持多人使用',
        subTitle: '一人充值、全班可用，无用户限制'
      }
    ],
    unit: '张',
    originPrice: 30,
    generalUser: { title: '9折优惠 限时福利', sendTitle: null, price: 27, id: 101, sendId: null },
    inviteUser: { title: '校园代理邀请 限时8折优惠', sendTitle: null, price: 24, id: 102, sendId: null }
  }
]
export const data1 = {
  title: '2025春节钜惠套餐',
  activity: 2,
  icon: 'icon-huodonglipin',
  desc: '全功能通用 字数&券永久有效 支持多人使用',
  time: '活动有效期：2025-1-16 至 2025-2-16',
  list: [
    {
      desc: [
        {
          // title: '5折钜惠 ￥74.5元/5万字',
          title1: '5折钜惠 ￥',
          title2: '74.5',
          title3: '元/5万字',
          // subTitle: '合￥14.9元/万字 字数多多 自由支配'
          subTitle1: '合',
          subTitle2: '￥14.9元/万字',
          subTitle3: ' 字数多多 自由支配'
        },
        {
          title1: '赠送PPT券一张',
          subTitle1: '开题、论文、降重、PPT  一个套餐就够了'
        }
      ],
      activity: 2,
      btn: '立享优惠（5万字）',
      words: 5,
      generalUser: { title: '双旦钜惠 限时5折 5万字起', sendTitle: '送一张PPT券', price: 14.9, id: 201, sendId: 1 },
      inviteUser: { title: '双旦钜惠 限时5折 5万字起', sendTitle: '送一张PPT券', price: 14.9, id: 201, sendId: 1 }
    },
    {
      desc: [
        {
          // title: '3折钜惠 ￥99元/10万字',
          title1: '3折钜惠 ￥',
          title2: '99',
          title3: '元/10万字',
          // subTitle: '合￥9.9元/万字 字数多多 自由支配'
          subTitle1: '合',
          subTitle2: '￥9.9元/万字',
          subTitle3: ' 字数多多 自由支配'
        },
        {
          title1: '赠送PPT券一张',
          subTitle1: '开题、论文、降重、PPT 一个套餐就够了'
        }
      ],
      activity: 2,
      btn: '立享优惠（10万字）',
      words: 10,
      generalUser: { title: '双旦钜惠 限时3折 10万字起', sendTitle: '送一张PPT券', price: 9.9, id: 202, sendId: 1 },
      inviteUser: { title: '双旦钜惠 限时3折 10万字起', sendTitle: '送一张PPT券', price: 9.9, id: 202, sendId: 1 }
    }
  ]
}
