import { memo, useState, useEffect, useContext } from 'react'
import { http } from '@/server/http'
import { apiGroup } from '@/server/api-group'
import ExplorationGroup from '@/assets/images/ExplorationGroup.png'
import { ThemeContext } from '@/store/store'
import './W5000w.less'

const W5000w = (props: any) => {
  const isGet = (sum_words: any) => {
    if (sum_words % 10000 == 5000) {
      return '已领取'
    } else {
      return '未领取'
    }
  }
  const { userInfo = {} } = useContext<any>(ThemeContext)
  const [text, setText] = useState(isGet(userInfo.sum_words))
  useEffect(() => {
    if (props.active == 0) {
      getUserInfo()
    }
  }, [props.active])
  const getUserInfo = async () => {
    const res: any = await http('post', apiGroup.userinfo, { unitoken: localStorage.getItem('uniToken') })
    setText(isGet(res.data.sum_words))
  }

  return (
    <div className='W5000w-container'>
      <img src={ExplorationGroup} />
      <span className='desc'>进群后请查看群公告</span>
      <div className='status'>
        <i className={`icon iconfont icon-duigougouxuan_xuanzhong ${text == '已领取' ? 'active' : ''}`} />
        {text}
      </div>
    </div>
  )
}

export default memo(W5000w)
