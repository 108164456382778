import { memo, useState, useEffect, useContext } from 'react'
import { ThemeContext, CHAT_DEFAULT } from '@/store/store'
import { LEFT, RIGHT, BTN_TEXT, GET_WORDS, NO_WORDS, CODE } from './const'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button, Popover, message } from 'antd'
import List from '../List/List'
import { http } from '@/server/http'
import { apiGroup } from '@/server/api-group'
// import BasicSpin from '@/components/BasicSpin/BasicSpin'
import { getUserInfoApi } from '@/server/commonFetch'
import './InviteNewUser.less'
const InviteNewUser = (props: any) => {
  const { dispatch } = useContext<any>(ThemeContext)
  const [active, setActive] = useState(props.active)
  const [data, setData] = useState<any>([])
  const [invite_code, setInvite_code] = useState<any>(null)
  // const [loading, setLoading] = useState<any>(true)
  useEffect(() => {
    setActive(props.active)
    agent_info()
  }, [props.active])
  const agent_info = async (copy = false) => {
    const res: any = await http('post', apiGroup.agent_info, { unionid: localStorage.getItem('uniToken') })
    if (res.status) {
      invite_list(res?.data?.invite_code)
      setInvite_code(res?.data?.invite_code)
      if (copy) {
        navigator.clipboard.writeText(CODE + res?.data?.invite_code)
        message.success('邀请文案 已复制')
      }
    }
  }
  const agent_apply = async () => {
    const uid = localStorage.getItem('uniToken')
    const name = '-'
    const res: any = await http('post', apiGroup.agent_apply, {
      unionid: uid,
      openid: uid,
      school: name,
      major: name,
      class: name,
      headimgurl: name,
      nickname: name
    })
    if (res.status && res.data) {
      agent_info(true)
    }
  }
  const invite_list = async (invite_code: string) => {
    const res: any = await http('post', apiGroup.invite_list, { invite_code })
    if (res.status) {
      const list = res.data.list
      // 排序将 sum_words > 0 和 attrited=1 有字数且被消费的排在前面，然后是 sum_words > 0 和 attrited=0 有字数且没被消费的 然后是  无字数且没被消费的
      list.sort((a: any, b: any) => {
        const getWeight = (item: { sum_words: number; attrited: number }) => {
          if (item.sum_words > 0 && item.attrited > 0) return 1 // 第一优先级
          if (item.sum_words > 0 && item.attrited === 0) return 2 // 第二优先级
          if (item.sum_words === 0 && item.attrited === 0) return 3 // 第三优先级
          return 4 // 默认优先级
        }
        return getWeight(a) - getWeight(b)
      })
      setData(list)
    }
    // setLoading(false)
  }
  const submit = async (s: any, e: any) => {
    if (s == 5) {
      if (data[4].attrited == 0) {
        message.warning('请先领取第一个奖励')
        return
      }
    }
    const unitokens = data.slice(s, e).map((item: any) => item.unitoken)
    const res: any = await http('post', apiGroup.invite_attrited, { unitokens })
    console.log(res)
    if (res.status) {
      invite_list(invite_code)
      pay()
    } else {
      message.error(res?.msg)
    }
  }
  const pay = async () => {
    const res: any = await http('post', active == 1 ? apiGroup.pay : apiGroup.pay_coupon, {
      id: 301,
      count: 1,
      unitoken: localStorage.getItem('uniToken')
    })
    if (res.status) {
      message.success('领取成功')
      getUserInfoApi({ dispatch, CHAT_DEFAULT })
    }
  }
  const isHaveWords = (words: any) => words > 0
  // 用户被消费
  const isAttrited = (index: any) => data[index]?.attrited == 1
  return (
    <div className='inviteNewUser-container'>
      {/* {loading ? <BasicSpin text='' theme='dark' /> : null} */}
      <div className='desc'>
        <List className='left' data={LEFT[active]} title='任务步骤：'>
          {invite_code ? (
            <CopyToClipboard text={CODE + invite_code} onCopy={() => message.success('邀请文案 已复制')}>
              <Button type='primary'>复制邀请链接</Button>
            </CopyToClipboard>
          ) : (
            <Button type='primary' onClick={agent_apply}>
              复制邀请链接
            </Button>
          )}
        </List>
        <List className='right' data={RIGHT[active]} title='任务说明：' />
      </div>
      <div className='users'>
        {Array.from({ length: 10 }).map((_, index) => {
          return (
            <div className='box' key={index}>
              {data[index] ? (
                <>
                  <Popover
                    content={
                      <div className='user-tip'>
                        <span className='utk'>uniToken:{data[index]?.unitoken.replace(/(?<=^.{4}).*(?=.{4}$)/, '****')}</span>
                        <span className='status'>{isHaveWords(data[index]?.sum_words) ? GET_WORDS : NO_WORDS}</span>
                      </div>
                    }
                    title=''
                    trigger='hover'
                    placement='topLeft'
                    arrow={{ pointAtCenter: true }}
                    overlayClassName='user-t-drop'
                  >
                    <i className={`icon iconfont icon-xueshengqia ${data[index]?.unitoken ? 'done' : ''}`} />
                  </Popover>
                  <i className={`icon iconfont icon-gouxuan ${isHaveWords(data[index]?.sum_words) ? 'done' : ''}`} />
                </>
              ) : (
                <i className='icon iconfont icon-xueshengqia' />
              )}
              {index == 4 && (
                <Button
                  type='primary'
                  className='_btn'
                  disabled={!isAttrited(index) && isHaveWords(data[index]?.sum_words) ? false : true}
                  onClick={() => submit(0, 5)}
                >
                  {isAttrited(index) ? '已领取' : BTN_TEXT[active]}
                </Button>
              )}
              {index == 9 && (
                <Button
                  type='primary'
                  className='_btn'
                  disabled={!isAttrited(index) && isHaveWords(data[index]?.sum_words) ? false : true}
                  onClick={() => submit(5, 10)}
                >
                  {isAttrited(index) ? '已领取' : BTN_TEXT[active]}
                </Button>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default memo(InviteNewUser)
