import { memo, useState, useEffect } from 'react'
import './BaseCountDown.less'

const BaseCountDown = (props: any) => {
  const [timeLeft, setTimeLeft] = useState('')
  const [day, setDay] = useState(0)

  const formatTime = (num: any, digits = 2) => String(num).padStart(digits, '0')

  useEffect(() => {
    // const targetDate: any = new Date(new Date().getFullYear() + 1, 0, 4) // 下一个元旦
    const targetDate: any = new Date(2025, 1, 17) // 到期 2 月 16 日

    const updateCountdown = () => {
      const now: any = new Date()
      const diff = targetDate - now
      if (diff <= 0) {
        setTimeLeft('活动结束')
        props.onCountDownEnd && props.onCountDownEnd()
        return
      }
      const days = Math.floor(diff / (1000 * 60 * 60 * 24)) // 天数
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24) // 小时
      const minutes = Math.floor((diff / (1000 * 60)) % 60) // 分钟
      const seconds = Math.floor((diff / 1000) % 60) // 秒
      const milliseconds = Math.floor((diff % 1000) / 100) // 毫秒，保留一位
      setDay(days)
      setTimeLeft(`${days}天 ${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}.${milliseconds}`)
    }

    const timer = setInterval(updateCountdown, 100) // 每 100ms 更新一次
    updateCountdown() // 初始化时调用一次

    return () => clearInterval(timer) // 组件卸载时清除定时器
  }, [])

  return (
    <div className={`BaseCountDown-contianer ${props.className}`} style={{ display: day > 9 ? 'none' : 'flex' }}>
      {timeLeft == '活动结束' ? (
        <span className='center'>活动结束</span>
      ) : (
        <>
          <span className='left'>全年最低价 仅剩 </span>
          <span className='right'>{timeLeft}</span>
        </>
      )}
    </div>
  )
}

export default memo(BaseCountDown)
