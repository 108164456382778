const data1 = ['发布原创图文或视频作品到本活动支持的任意平台 (小红书、快手、抖音)，图片数量和视频时长不限;']
const data2 = ['① 作品中必须包含Al UniPaper首页 (第一屏黑色背景页）', '② 作品必须带上话题 #AIUniPaper论文写作 (注意大小写)']
const data4 = [
  '① 单平台的点赞数和播放量需同时满足才可获得奖励',
  '② 每个平台、每个作品只可领取一次奖励',
  '③ 领取奖励后作品需保留30天以上',
  '④ 提交完成要求的作品链接及播放量截图，审核成功后奖励自动发放'
]
export const DATA1 = {
  3: data1,
  4: data1
} as any

export const DATA2 = {
  3: data2,
  4: data2
} as any

export const DATA3 = {
  3: ['播放量2000以上、点赞20以上'],
  4: ['播放量5000以上、点赞50以上']
} as any

export const DATA4 = {
  3: data4,
  4: data4
} as any
