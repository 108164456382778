import { memo, useState, useEffect } from 'react'
import { Modal } from 'antd'
import './ActivityModal.less'
const ActivityModal = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(props.visible)
  const [closeClass, setCloseClass] = useState(props.closeClass)
  const [bgImg, setBgImgs] = useState(props.bgImg)
  useEffect(() => {
    setIsModalOpen(props.visible)
  }, [props.visible])
  useEffect(() => {
    setCloseClass(props.closeClass)
  }, [props.closeClass])
  useEffect(() => {
    setBgImgs(props.bgImg)
  }, [props.bgImg])
  const handleOk = () => {
    if (props.onOk) props.onOk()
  }
  const handleCancel = () => {
    if (props.onCancel) props.onCancel()
  }
  return (
    <Modal
      wrapClassName={`${props.className || ''} activityModal-modal-container ${!closeClass ? 'closing' : 'opening'}`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      title=''
      footer={null}
      transitionName='' // 禁用 Modal 动画
      // maskTransitionName='' // 禁用遮罩动画
      centered
    >
      <div className='activityModal-content'>
        <img className='activity-bg' src={bgImg} alt='' />
        {props.activity1 && <img className='activity-1 activity' src={props.activity1} alt='' onClick={() => props.buy(5)} />}
        {props.activity2 && <img className='activity-2 activity' src={props.activity2} alt='' onClick={() => props.buy(10)} />}
      </div>
    </Modal>
  )
}

export default memo(ActivityModal)
