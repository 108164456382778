import { memo, useState, useEffect } from 'react'
import { Modal } from 'antd'
import W5000w from './W5000w/W5000w'
import InviteNewUser from './InviteNewUser/InviteNewUser'
import VideoCreation from './VideoCreation/VideoCreation'
import SvgIcon from '@/components/SvgIcon/SvgIcon'
import { TITLES, FOOTER } from './const'
import './GetWordModal.less'
const GetWordModal = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(props.visible)
  const [active, setActive] = useState(0)
  useEffect(() => {
    setActive(0)
    setIsModalOpen(props.visible)
  }, [props.visible])

  const handleOk = () => {
    if (props.onOk) {
      props.onOk()
    }
  }
  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel()
      setActive(-1)
    }
  }
  return (
    <Modal
      wrapClassName={`${props.className || ''} getWordModal-modal-container`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      title=''
      footer={null}
      centered
    >
      <div className='title'>
        {TITLES[active]}
        {[3, 4].includes(active) && (
          <div className='icon-box'>
            {['icon-xiaohongshu-hui', 'icon-kuaishou', 'icon-douyin'].map((item: any, index: number) => {
              return <SvgIcon key={index} className='icon-video' href={`${item}`} />
            })}
          </div>
        )}
      </div>
      <div className='content'>
        {active === 0 && <W5000w active={active} />}
        {[1, 2].includes(active) && <InviteNewUser active={active} />}
        {[3, 4].includes(active) && <VideoCreation active={active} />}
      </div>
      <ul className='footer'>
        {FOOTER.map((item: any, index: number) => {
          return (
            <li key={index} className={active == index ? 'active' : ''} onClick={() => setActive(index)}>
              <div className='p1'>
                <span>{item.title}</span>
                <span>{item.weight}</span>
              </div>
              <div className='p2'>{item.subTitle}</div>
            </li>
          )
        })}
      </ul>
    </Modal>
  )
}

export default memo(GetWordModal)
