import { useRef, useState, Fragment } from 'react'
import './ImageCarousel.less'

const ImageCarousel = (props: any) => {
  const scrollContainerRef = useRef<any>(null)
  // const [speed] = useState(props.speed || 1) // 滚动速度（像素/帧）
  const [direction] = useState(props.direction || 'right') // 滚动方向（"right" 或 "left"）
  const [imgs] = useState(props.imgs)

  // useEffect(() => {
  // const scrollContainer = scrollContainerRef.current
  // const scrollInterval = setInterval(() => {
  // if (direction === 'right') {
  //   if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
  //     scrollContainer.scrollLeft = 0
  //   } else {
  //     scrollContainer.scrollLeft += speed // 根据速度调整滚动步长
  //   }
  // } else {
  //   if (scrollContainer.scrollLeft <= 0) {
  //     scrollContainer.scrollLeft = scrollContainer.scrollWidth / 2
  //   } else {
  //     scrollContainer.scrollLeft -= speed // 根据速度调整滚动步长
  //   }
  // }
  // }, 16) // 60FPS

  // return () => {
  //   clearInterval(scrollInterval) // 清除定时器，防止内存泄漏
  // }
  // }, [speed, direction]) // 当 speed 或 direction 改变时重新启动滚动逻辑

  return (
    <div className={`carousel-container carousel-container-${direction}`} ref={scrollContainerRef}>
      <div className='carousel-track'>
        {/* 将图片渲染两次以实现无缝滚动 */}
        {[...Array(2)].map((_, index) => (
          <Fragment key={index}>
            {imgs.map((_: any, i: number) => (
              <div className='carousel-item' key={`${index}-${i}`}>
                <img src={_} alt='' />
              </div>
            ))}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default ImageCarousel
