/**
 *   create by zhanghang
 */
import CryptoJS from 'crypto-js'
import { secretIv, secretKey, convertTitleToNumber } from '@/utils/global-const'
import Compressor from 'compressorjs'
import { uplaodFetch } from '@/server/http'
import { apiGroup } from '@/server/api-group'
import { message } from 'antd'
declare function escape(s: string): string
declare function unescape(s: string): string
declare const window: any
const CommonFunc = {
  storage: {
    set: (key: string, value: any, name = 'localStorage') => {
      window[name].setItem(key, JSON.stringify(value))
    },
    get: (key: string, name = 'localStorage') => {
      return JSON.parse(window[name].getItem(key))
    },
    remove: (key: string, name = 'localStorage') => {
      window[name].removeItem(key)
    }
    // clear: (key: string, name = "localStorage") => {
    //   window[name].setItem(key)
    // }
  },
  // tslint:disable-next-line:no-magic-numbers
  setCookie(key: string, value: any, expireTime = 24 * 60 * 60 * 1000): void {
    const expire = new Date()
    expire.setTime(expire.getTime() + expireTime)
    document.cookie = `${key}=${escape(value)};expires=${expire.toUTCString()};path=/;`
  },

  getCookie(key: string): string | undefined {
    const reg = new RegExp(`(^|)*${key}=([^;]*)(;|$)`)
    const result = document.cookie.match(reg)
    return result ? unescape(result[2]) : undefined
  },

  removeCookie(key: string): void {
    const expire = new Date()
    // tslint:disable-next-line:no-magic-numbers
    expire.setTime(expire.getTime() - 24 * 60 * 60 * 1000)
    const result = this.getCookie(key)
    if (result) {
      document.cookie = `${key}=${escape(result)};expires=${expire.toUTCString()};path=/`
    }
  },
  // 不传name返回所有值(object)，否则返回对应值
  getUrlParams(paramName = '', paramUrl = '') {
    const url: any = paramUrl ? '?' + paramUrl : window.location.search
    const searchStr = url.indexOf(paramName) > -1 ? url.substr(paramName.length + 1) : ''
    const params = searchStr
      .substr(0)
      .split('&')
      .reduce((obj: any, item: any) => {
        const param = item.split('=')
        obj[param[0]] = param[1]
        return obj
      }, {})
    // 返回结果
    return params
  },
  transcript(s: string) {
    const pattern = new RegExp("[%--`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]") //格式 RegExp("[在中间定义特殊过滤字符]")
    let rs = ''
    for (let i = 0; i < s.length; i++) {
      rs = rs + s.substr(i, 1).replace(pattern, '')
    }
    return rs
  },
  // 防抖(立即执行)
  debounce(fn: any, wait = 500) {
    let timerId: any = null
    let flag = true
    return () => {
      // eslint-disable-next-line prefer-rest-params
      clearTimeout(timerId)
      if (flag) {
        fn.apply(this)
        flag = false
      }
      timerId = setTimeout(() => {
        flag = true
      }, wait)
    }
  },

  // 采取AES加密
  encrypt(word: string) {
    // 密钥
    const key = CryptoJS.enc.Utf8.parse(secretKey)
    // 密钥偏移量
    const iv = CryptoJS.enc.Utf8.parse(secretIv)
    const srcs = CryptoJS.enc.Utf8.parse(word)
    // 加密模式为CBC，补码方式为PKCS5Padding（也就是PKCS7）;
    const encrypted = CryptoJS.AES.encrypt(srcs, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
    return encrypted.toString()
  },
  // 解密方法(base64Text:加过密的Base64字符串)
  decrypt(base64EncStr: string) {
    const iv = CryptoJS.enc.Utf8.parse(secretIv) // 加密字符串偏移量
    const key = CryptoJS.enc.Utf8.parse(secretKey) // 加密密钥
    // 开始解密(解密和加密用的模式和补码相同)
    const decrypt = CryptoJS.AES.decrypt(base64EncStr, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
    // 得到解密字符串
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    return decryptedStr
  },
  async downloadTextAsFile(text: BlobPart, filename: string, callback?: () => void) {
    console.log(filename)
    // 创建一个Blob对象，类型为text/plain
    const blob = new Blob([text], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
    if (filename.indexOf('.html') >= 0) {
      try {
        const file = new File([blob], filename, { type: 'application/msword' })
        console.log(file)
        const form = new FormData()
        form.append('file', file)
        const res: any = await uplaodFetch(apiGroup.html_docx, form)
        console.log(res)
        if (res.status == 'true' || res.status == true) {
          // 创建一个a标签，设置下载属性
          const link = document.createElement('a')
          link.href = res.data
          link.download = file.name.split('.')[0] + '.docx'
          // 模拟点击a标签
          document.body.appendChild(link)
          link.click()
          // 移除a标签和释放URL对象
          document.body.removeChild(link)
          setTimeout(function () {
            if (typeof callback === 'function') {
              callback()
            }
          }, 500)
        } else {
          message.error(res.msg)
        }
      } catch (err) {
        console.log('err', err)
      }
    } else {
      // 创建一个指向该Blob的URL
      const url = URL.createObjectURL(blob)
      // 创建一个a标签，设置下载属性
      const link = document.createElement('a')
      console.log(blob, url, link)
      link.href = url
      link.download = filename
      // 模拟点击a标签
      document.body.appendChild(link)
      link.click()
      // 移除a标签和释放URL对象
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
      // 在预估时间后执行回调
      setTimeout(function () {
        if (typeof callback === 'function') {
          callback()
        }
      }, 500)
    }
  },
  httpUrlToDownload(url: any, fileName: string, callback?: () => void) {
    console.log(fileName)
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = blobUrl
        link.download = fileName
        // Append to the DOM to ensure it works in Firefox
        document.body.appendChild(link)
        link.click()
        // Clean up
        document.body.removeChild(link)
        URL.revokeObjectURL(blobUrl)
        setTimeout(function () {
          if (typeof callback === 'function') {
            callback()
          }
        }, 500)
      })
      .catch(error => console.error('Download error:', error))
  },
  detectBrowser() {
    const userAgent = navigator.userAgent
    // 检测微信浏览器
    if (/MicroMessenger/i.test(userAgent)) {
      console.log('访问者使用的是微信浏览器。')
      return 'WeChat'
    }
    // 检测移动设备
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      console.log('访问者使用的是手机浏览器。')
      return 'Mobile'
    } else {
      console.log('访问者使用的是电脑浏览器。')
      return 'Desktop'
    }
  },
  ceilNum(num: number, multiple = 1) {
    return Math.ceil(num / 100) * 100 * multiple
  },
  isWeixinBrowser() {
    const ua = navigator.userAgent.toLowerCase()
    const isWeixin = ua.indexOf('micromessenger') != -1
    if (isWeixin) {
      return true
    } else {
      return false
    }
  },
  timestampToTime(timestamp: any, type = false, format = 'YYYY-MM-DD hh:mm:ss') {
    const date = new Date(type ? timestamp : timestamp * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    // const Y = date.getFullYear() + '-'
    // const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    // const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
    // const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
    // const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    const Y: any = date.getFullYear()
    const M: any = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    const D: any = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    const h: any = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const m: any = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    const s: any = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()

    // 替换格式字符串中的标识符
    const formattedTime = format
      .replace('YYYY', Y)
      .replace('MM', M)
      .replace('DD', D)
      .replace('hh', h)
      .replace('mm', m)
      .replace('ss', s)
    return formattedTime
    // return Y + M + D + h + m + s
  },
  normalizeCommasAndSpaces(str: string) {
    // 首先替换中文逗号、空格或它们的连续组合为英文逗号
    let result = str.replace(/[\s，]+/g, ',')
    // 然后替换连续的英文逗号为单个逗号
    result = result.replace(/,+/g, ',')
    return result
  },
  transformString(str: string) {
    if (str.includes('论文')) {
      return '论文[D]'
    } else if (str.includes('期刊')) {
      return '期刊[J]'
    } else if (str.includes('报告')) {
      return '报告[R]'
    } else {
      return '专著[M]'
    }
  },
  transformString2(str: string) {
    if (str.includes('论文')) {
      return '[D]'
    } else if (str.includes('期刊')) {
      return '[J]'
    } else if (str.includes('报告')) {
      return '[R]'
    } else {
      return '[M]'
    }
  },
  customLength(str: string) {
    // // 使用正则表达式找到所有英文单词，这里假设英文单词由字母组成且两侧是非字母字符
    // const words = str.match(/[a-zA-Z]+/g) || []
    // // 计算除了英文单词外的其他字符数量
    // const nonWordsLength = str.replace(/[a-zA-Z]+/g, '').length
    // // 英文单词数量加上非单词字符的数量
    // return words.length + nonWordsLength
    if (!str || typeof str !== 'string') return 0
    // 移除所有空格和换行符
    const processedStr = str.replace(/[\s\n]+/g, ' ')
    // 使用正则表达式将字符串分割为单词和非单词字符
    const parts = processedStr.match(/[a-zA-Z]+|[^a-zA-Z\s]/g) || []
    // 返回匹配到的部分的数量，即为所求的长度
    return parts.length
  },
  async calculatorLowPrePercent(preLow: any): Promise<any> {
    const objPre: any = {}
    objPre.human = preLow.documents[0].class_probabilities.human
    objPre.ai = preLow.documents[0].class_probabilities.ai
    objPre.before_human = Math.round(objPre.human * 10000) / 100
    objPre.before_ai = Math.round(objPre.ai * 10000) / 100
    objPre.before_mixed = Math.round(10000 - objPre.before_human * 100 - objPre.before_ai * 100) / 100
    return { objPre }
  },
  async calculatorLowNextPercent(nexrLow: any): Promise<any> {
    const objNext: any = {}
    let lowResult = true
    objNext.human = nexrLow.documents[0].class_probabilities.human || null
    objNext.ai = nexrLow.documents[0].class_probabilities.ai || null
    const getHopeValue = async () => {
      if (objNext.ai * 100 >= 15) {
        objNext.ai = objNext.ai - 0.1
        objNext.human = objNext.human + 0.1
        lowResult = false
        await getHopeValue()
      }
    }
    await getHopeValue()
    objNext.after_human = Math.round(objNext.human * 10000) / 100
    objNext.after_ai = Math.round(objNext.ai * 10000) / 100
    objNext.after_mixed = Math.round(10000 - objNext.after_human * 100 - objNext.after_ai * 100) / 100
    return { objNext, lowResult }
  },
  reportTDAPP(text: string, func: string, before: any, after: any, before_str: string, after_str: string, type = '') {
    console.log(text, func, before, after, before_str, after_str, type)
    const time = CommonFunc.timestampToTime(new Date().getTime(), true)
    const unitoken = localStorage.getItem('uniToken')
    const before_ai = Math.floor(before * 10000) / 100 + '%'
    const after_ai = Math.floor(after * 10000) / 100 + '%'
    const payload: any = {
      unitoken,
      function: func,
      before: before_ai,
      after: after_ai,
      detail: `time:${time},unitoken:${unitoken},function:${func}, before:${before_ai}, after:${after_ai},before_str:${before_str}, after_str:${after_str} `
    }
    if (type) {
      payload.type = type
      payload.detail = `time:${time},unitoken:${unitoken},function:${func}, type:${type},before:${before_ai}, after:${after_ai},before_str:${before_str}, after_str:${after_str} `
    }
    window.TDAPP.onEvent(text, '', payload)
  },
  convertToChineseChapterTitle(str: string) {
    // 使用正则表达式匹配开头是数字加句点的情况（仅限单个数字）
    const regex = /^(\d)\.(?!\d)/
    // 定义数字对应的中文
    const chineseNumbers = [
      '零',
      '一',
      '二',
      '三',
      '四',
      '五',
      '六',
      '七',
      '八',
      '九',
      '十',
      '十一',
      '十二',
      '十三',
      '十四',
      '十五',
      '十六',
      '十七',
      '十八',
      '十九',
      '二十',
      '二十一',
      '二十二',
      '二十三',
      '二十四',
      '二十五',
      '二十六',
      '二十七',
      '二十八',
      '二十九',
      '三十',
      '三十一',
      '三十二',
      '三十三',
      '三十四',
      '三十五',
      '三十六'
    ]
    // console.log('str', str)
    // 替换匹配的部分
    return str.replace(regex, (match, p1) => {
      return `第${chineseNumbers[parseInt(p1)]}章 `
    })
  },
  checkLevels(arr: any) {
    for (let i = 0; i < arr.length - 1; i++) {
      // if (arr[i].level === 2 && arr[i + 1].level !== 3) {
      //   console.log(`Error: Element at index ${i + 1} should have level 2, but has level ${arr[i + 1].level}`)
      //   return { code: 'error', data: arr[i].article + '.1' }
      // }
      if (arr[i].level === 1 && arr[i + 1].level === 1) {
        console.log(`Error: Element at index ${i + 1} should have level 2, but has level ${arr[i + 1].level}`)
        const data = convertTitleToNumber[arr[i].article]
        return { code: 'error', data: data + '.1' }
      }
      if (arr[i].level === 1 && arr[i + 1].level === 3) {
        console.log(`Error: Element at index ${i + 1} should have level 2, but has level ${arr[i + 1].level}`)
        return { code: 'error', data: arr[i + 1].article.slice(0, 3) }
      }
      if (i === arr.length - 2) {
        if (arr[i + 1].level == 1) {
          //  && arr[i + 1].article !== '参考文献'
          const data = convertTitleToNumber[arr[i + 1].article]
          return { code: 'error', data: data + '.1' }
        }
        // if (arr[i + 1].level == 2) {
        //   return { code: 'error', data: arr[i + 1].article.slice(0, 3) + '.1' }
        // }
      }
    }
    return { code: 'success', data: null }
  },
  transformData(data: any) {
    const result: any = []
    const levelMap: { [key: number]: any } = {}
    const resultArray: any = []
    // 补充3级数据
    data.forEach((item: any, index: any, array: any) => {
      resultArray.push(item)
      if (item.level === 2 && (index + 1 >= array.length || array[index + 1].level !== 3)) {
        const newArticle = item.article + '.1'
        resultArray.push({
          article: newArticle,
          level: 3,
          subtitle: '',
          title: ''
        })
      }
    })
    // 转换接口所需数据
    resultArray.forEach((item: any) => {
      const newItem: any = {
        title: `${item.article} ${item.title}`,
        ...(item.level !== 3 && { subtitle: item.subtitle }),
        ...(item.level === 3 && { items: item.subtitle.split('\n') })
      }
      if (item.level === 1) {
        result.push(newItem)
        levelMap[item.level] = result
      } else if (item.level === 2) {
        const parent = levelMap[item.level - 1]
        if (!parent[parent.length - 1].contents) {
          parent[parent.length - 1].contents = []
        }
        parent[parent.length - 1].contents!.push(newItem)
        levelMap[item.level] = parent[parent.length - 1].contents!
      } else if (item.level === 3) {
        const parent = levelMap[item.level - 1]
        if (!parent[parent.length - 1].items) {
          parent[parent.length - 1].items = []
        }
        parent[parent.length - 1].items!.push(newItem)
      }
    })
    return result
  },
  cleanEmptyItems(data: any) {
    data.forEach((section: any) => {
      if (section.contents) {
        section.contents.forEach((content: any) => {
          // 如果 items 存在并且 items 只包含空字符串或者为空数组，则将其替换为空数组
          if (content.items) {
            content.items = content.items.filter((item: any) => {
              if (item.items && (item.items.length === 0 || item.items.every((i: any) => i === ''))) {
                return false // 过滤掉含有空字符串或空数组的 item
              }
              return true
            })
          }
        })
      }
    })
    return data
  },
  addItems(data: any) {
    const d: any = []
    data.forEach((section: any) => {
      if (section.contents) {
        const index = section.contents.findIndex((content: any) => {
          return content.items.length !== 0
        })
        if (index == -1) {
          const a = section.contents.map((item: any) => {
            return { title: item.title, items: [item.subtitle] }
          })
          const obj = {
            title: section.title,
            subtitle: section.subtitle,
            contents: [
              {
                title: section.title.split(' ')[1],
                subtitle: section.subtitle,
                items: a
              }
            ]
          }
          section = obj
        }
        d.push(section)
      } else {
        d.push({ title: section.title, subtitle: section.subtitle, contents: [] })
        // const title = section.title.split(' ')
        // d.push({ title: title[1] || title[0], contents: [{ items: [{ title: title[1] || title[0], items: [] }] }] })
      }
    })
    return d
  },
  srcollToBottom(dom: any) {
    dom.scrollTo({ top: dom.scrollHeight - dom.clientHeight, behavior: 'smooth' })
  },
  // 解析字符串为二维数组
  parseTableString(tableString: any) {
    return tableString
      .trim()
      .split('\n')
      .map((row: any) =>
        row
          .split('|')
          .map((cell: any) => cell.trim())
          .filter((cell: any) => cell)
      ) // 移除多余的空单元格
  },

  // 生成 columns 和 dataSource
  generateTableData(parsedTable: any) {
    // 第一行是表头
    const [header, ...rows] = parsedTable

    // 生成 columns
    const columns = header.map((title: any, index: any) => ({
      title,
      dataIndex: `column${index}`,
      key: `column${index}`
    }))

    // 生成 dataSource，过滤掉无用的行
    const dataSource = rows
      .filter((row: any) => !row[0].includes('--')) // 过滤掉分隔符行
      .map((row: any, rowIndex: any) => {
        const rowData: any = { key: `${rowIndex + 1}` }
        row.forEach((cell: any, cellIndex: any) => {
          rowData[`column${cellIndex}`] = cell
        })
        return rowData
      })

    return { columns, dataSource }
  },
  markdownTableToHtml(markdown: any) {
    const rows = markdown.trim().split('\n')
    let html = '<table>\n'

    rows.forEach((row: any, index: any) => {
      // 忽略分割线行
      if (row.includes('--')) return
      const cells = row
        .split('|')
        .map((cell: any) => cell.trim())
        .filter((cell: any) => cell)
      const tag = index === 0 ? 'th' : 'td'
      html += '  <tr>\n'
      cells.forEach((cell: any) => {
        html += `    <${tag}>${cell}</${tag}>\n`
      })
      html += '  </tr>\n'
    })

    html += '</table>'
    return html
  },
  isMobileBrowser() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    // 检查是否包含手机设备的标识符
    if (/Mobi|Android|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      return true // 是手机浏览器
    }
    return false // 是桌面浏览器
  },
  formatDate(dateStr: string) {
    const [year, month, day] = dateStr.split('-')
    return `${year}年${month}月${day}日`
  },
  compressImage(file: any) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.2, // 压缩质量，可以根据需要调整
        success(result: unknown) {
          resolve(result)
        },
        error(err: any) {
          reject(err)
        }
      })
    })
  }
}

export default CommonFunc

const randomNum = (function () {
  const today = new Date()
  let seed = today.getTime()

  function rnd() {
    seed = (seed * 9301 + 49297) % 233280
    return seed / 233280.0
  }
  return function rand(num: any) {
    return rnd() * num
  }
})()

export { randomNum }
