export const TITLES = [
  '加官方社群 免费领5,000体验字数',
  '邀请新同学 免费领10,000字奖励',
  '邀请新同学 免费领PPT券奖励',
  '发布原创作品 领取20,000字奖励',
  '发布原创作品 领取50,000字奖励'
]
export const FOOTER = [
  {
    title: '免费领 ',
    weight: '5,000字',
    subTitle: '加官方社群 参与学生党互动'
  },
  {
    title: '邀请领 ',
    weight: '10,000字',
    subTitle: '邀请新同学 最高两万字奖励'
  },
  {
    title: '邀请领 ',
    weight: 'PPT券',
    subTitle: '邀请新同学 最高两张券奖励'
  },
  {
    title: '任务领 ',
    weight: '20,000字',
    subTitle: '初级短视频创作者 轻松领取'
  },
  {
    title: '任务领 ',
    weight: '50,000字',
    subTitle: '实力短视频创作者 轻松领取'
  }
]
